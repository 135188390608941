import { AppHeaderConfigPayload } from 'admin-portal-shared-services'
import { ModulesPages } from 'routes/Definition'
import { HOME_PATH } from '@constants'

const homePath = HOME_PATH

export const defaultAppHeaderConfig: AppHeaderConfigPayload = {
  pageTitle: 'Dashboard | PO Reader',
  breadcrumbConfig: {
    homePath,
    items: [
      {
        isCurrentPage: true,
        path: '',
        label: 'Dashboard',
      },
    ],
  },
  countrySelect: true,
  vendorSelect: true,
  countryOptions: [],
}

export const ordersAppHeaderConfig: AppHeaderConfigPayload = {
  pageTitle: 'Orders | PO Reader',
  breadcrumbConfig: {
    homePath,
    items: [
      {
        isCurrentPage: true,
        path: '/orders',
        label: 'Orders',
      },
    ],
  },
  vendorSelect: true,
  countrySelect: true,
}

export const listPocsActivationAppHeaderConfig: AppHeaderConfigPayload = {
  pageTitle: 'Pocs Activation List | PO Reader',
  breadcrumbConfig: {
    homePath,
    items: [
      {
        isCurrentPage: true,
        path: '/list/pocs-activation',
        label: 'Pocs Activation List',
      },
    ],
  },
  vendorSelect: true,
  countrySelect: true,
}

export const listLayoutsAppHeaderConfig: AppHeaderConfigPayload = {
  pageTitle: 'Layouts list | PO Reader',
  breadcrumbConfig: {
    homePath,
    items: [
      {
        isCurrentPage: true,
        path: '/list/layouts',
        label: 'Layouts list',
      },
    ],
  },
  vendorSelect: true,
  countrySelect: true,
}

export const listAccountsIdAppHeaderConfig: AppHeaderConfigPayload = {
  pageTitle: 'Account details | PO Reader',
  breadcrumbConfig: {
    homePath,
    items: [
      {
        isCurrentPage: false,
        path: `${homePath}/list/layouts`,
        label: 'Layouts list',
      },
      {
        isCurrentPage: true,
        path: '/list/layouts/:id',
        label: 'Account details',
      },
    ],
  },
}

export const curatorshipAppHeaderConfig: AppHeaderConfigPayload = {
  pageTitle: 'Curatorship details | PO Reader',
  breadcrumbConfig: {
    homePath,
    items: [
      {
        isCurrentPage: true,
        path: '/curatorship/:id',
        label: 'Curatorship details',
      },
    ],
  },
}

export const curatorshipItemsAppHeaderConfig: AppHeaderConfigPayload = {
  pageTitle: 'Curatorship Items | PO Reader',
  breadcrumbConfig: {
    homePath,
    items: [
      {
        isCurrentPage: false,
        path: `${homePath}/curatorships`,
        label: 'Curatorship details',
      },
      {
        isCurrentPage: true,
        path: `${homePath}/curatorship/:id/items`,
        label: 'Curatorship Items',
      },
    ],
  },
  vendorSelect: true,
  countrySelect: true,
};

export const trainingAppHeaderConfig: AppHeaderConfigPayload = {
  pageTitle: 'Training file | PO Reader',
  breadcrumbConfig: {
    homePath,
    items: [
      {
        isCurrentPage: false,
        path: `${homePath}/list/layouts`,
        label: 'Layouts list',
      },
      {
        isCurrentPage: true,
        path: '/list/layouts/training/:id',
        label: 'Training details',
      },
    ],
  },
  countrySelect: true,
  vendorSelect: true,
}

export const viewOrderItemsAppHeaderConfig: AppHeaderConfigPayload = {
  pageTitle: 'View Order Items | PO Reader',
  breadcrumbConfig: {
    homePath,
    items: [
      {
        isCurrentPage: false,
        path: `${homePath}/orders`,
        label: 'Orders',
      },
      {
        isCurrentPage: true,
        path: '/orders/view-items',
        label: 'Items',
      },
    ],
  },
  vendorSelect: true,
  countrySelect: true,
}

export const getAppHeaderConfig: (
  pageName: ModulesPages
) => Promise<AppHeaderConfigPayload> = async (pageName) => {
  await import('routes/Definition')

  switch (pageName) {
    case 'LIST_LAYOUTS':
      return listLayoutsAppHeaderConfig
    case 'LIST_POCS_AND_VENDORS':
      return listAccountsIdAppHeaderConfig
    case 'CURATORSHIPS':
      return curatorshipAppHeaderConfig
    case 'CURATORSHIP_ITEMS':
      return curatorshipItemsAppHeaderConfig
    case 'TRAINING':
      return trainingAppHeaderConfig
    case 'ORDERS':
      return ordersAppHeaderConfig
    case 'LIST_POCS_ACTIVATION':
      return listPocsActivationAppHeaderConfig
    case 'VIEW_ORDER_ITEMS':
      return viewOrderItemsAppHeaderConfig
    default:
      return defaultAppHeaderConfig
  }
}